import { useMemo } from 'react';
import { getProfilePublicRoute, getShortAddress } from '@utils';
import { ImageWithFallback, InternalLink, UserBadge, GalleryBadge } from '@components/atoms';

import EthIcon from '@assets/icons/eth-icon.svg';
// import MoreIcon from '@assets/icons/black-more-icon.svg';
import styles from './style.module.scss';
import { IArtistUser, IGalleryUser } from '@constants/types';

interface IProps {
  user: IArtistUser & IGalleryUser;
}

const MarketProfileCard = ({ user }: IProps) => {
  const {
    _id,
    username,
    role,
    wallet,
    avatar,
    banner,
    gallery,
    artists,
    artworks,
    /* hasPlatinumMembership,
    hasStandardMembership, */
  } = useMemo(
    () => ({
      ...user,
      arts: user.copies?.length || 0,
    }),
    [user],
  );
  // @TODO add wallet to be returned from BE. Old bug
  return (
    <InternalLink to={getProfilePublicRoute(_id, role.name)} className={styles.link}>
      <div className={styles.root}>
        <div className={styles.root__cover}>
          <ImageWithFallback src={banner} fallback="defaultBanner" />
        </div>
        <div className={styles.root__main}>
          <ImageWithFallback src={avatar} fallback="defaultAvatar" className={styles.root__avatar} />
          {/* <div className={styles.root__item__controller}> */}
          {/*   <button className={styles.root__btn}> */}
          {/*     <img src={MoreIcon} alt="" /> */}
          {/*   </button> */}
          {/* </div> */}
        </div>
        <div className={styles.root__info}>
          <div className={styles.root__group}>
            <div className={styles.root__group__name}>{username}</div>
            <UserBadge role={role.name ?? 'artist'} />
          </div>
          {!!wallet && (
            <div className={styles.root__group__eth}>
              <img src={EthIcon} alt="" />
              {getShortAddress(wallet)}
            </div>
          )}
          {!!gallery && (
            <div className={styles.root__GalleryBadge}>
              <div className={styles.root_artworks__item_badge}>
                <p className={styles.root__text}>Member of</p>
                <GalleryBadge gallery={gallery} size="sm" />
              </div>
              {role.name === 'artist' && (
                <div className={styles.root_artworks__item}>
                  <p className={styles.root_artworks__number}>{artworks ?? 0}</p>
                  <p className={styles.root_artworks__text}>Artworks</p>
                </div>
              )}
            </div>
          )}
          {role.name === 'gallery' && (
            <div className={styles.root_artworks}>
              <div className={styles.root_artworks__item}>
                <p className={styles.root_artworks__number}>{artworks ?? 0}</p>
                <p className={styles.root_artworks__text}>Artworks</p>
              </div>
              <div className={styles.root_artworks__item}>
                <p className={styles.root_artworks__number}>{artists ?? 0}</p>
                <p className={styles.root_artworks__text}>Artists</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </InternalLink>
  );
};

export default MarketProfileCard;
