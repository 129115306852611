interface propTypes {
  width?: number;
  height?: number;
  fill?: string;
  type?: string;
}

const types: { [key: string]: string } = {
  default: `M3 1C2.05719 1 1.58579 1 1.29289 1.29289C1 1.58579 1 2.05719 1 3L1 9.06325C1 9.52389 1 9.75421 1.09441 
  9.95578C1.18882 10.1574 1.36576 10.3048 1.71963 10.5997L5.71963 13.933C6.33184 14.4432 6.63795 14.6983 7 14.6983C7.36205 
  14.6983 7.66816 14.4432 8.28037 13.933L12.2804 10.5997C12.6342 10.3048 12.8112 10.1574 12.9056 9.95578C13 
  9.75421 13 9.52389 13 9.06325V3C13 2.05719 13 1.58579 12.7071 1.29289C12.4142 1 11.9428 1 11 1L3 1Z`,
  left: `M15 3C15 2.05719 15 1.58579 14.7071 1.29289C14.4142 1 13.9428 1 13 1H6.93675C6.47611 1 6.24579 1 6.04422 
  1.09441C5.84265 1.18882 5.6952 1.36576 5.40031 1.71963L2.06697 5.71963C1.5568 6.33184 1.30171 6.63795 1.30171 7C1.30171 
  7.36205 1.5568 7.66816 2.06697 8.28037L5.40031 12.2804C5.6952 12.6342 5.84265 12.8112 6.04422 12.9056C6.24579 
  13 6.47611 13 6.93675 13H13C13.9428 13 14.4142 13 14.7071 12.7071C15 12.4142 15 11.9428 15 11V3Z`,
  right: `M1 3C1 2.05719 1 1.58579 1.29289 1.29289C1.58579 1 2.05719 1 3 1H9.06325C9.52389 1 9.75421 1 9.95578 
  1.09441C10.1574 1.18882 10.3048 1.36576 10.5997 1.71963L13.933 5.71963C14.4432 6.33184 14.6983 6.63795 14.6983 
  7C14.6983 7.36205 14.4432 7.66816 13.933 8.28037L10.5997 12.2804C10.3048 12.6342 10.1574 12.8112 9.95578 
  12.9056C9.75421 13 9.52389 13 9.06325 13H3C2.05719 13 1.58579 13 1.29289 12.7071C1 12.4142 1 11.9428 1 11V3Z`,
};

const Mark = ({ width = 16, height = 16, fill = 'white', type = 'default' }: propTypes) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={types[type]}
      stroke={fill}
      strokeWidth="2"
    />
  </svg>

);

export default Mark;
