interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

const More = ({ width = 14, height = 4, fill = '#000' }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 0.488281C1.69141 0.488281 0.988281 1.19141 0.988281 2C0.988281 2.80859 1.69141 3.51172 2.5 3.51172C3.30859 3.51172 4.01172 2.80859 4.01172 2C4.01172 1.19141 3.30859 0.488281 2.5 0.488281ZM11.5 0.488281C10.6914 0.488281 9.98828 1.19141 9.98828 2C9.98828 2.80859 10.6914 3.51172 11.5 3.51172C12.3086 3.51172 13.0117 2.80859 13.0117 2C13.0117 1.19141 12.3086 0.488281 11.5 0.488281ZM7 0.488281C6.19141 0.488281 5.48828 1.19141 5.48828 2C5.48828 2.80859 6.19141 3.51172 7 3.51172C7.80859 3.51172 8.51172 2.80859 8.51172 2C8.51172 1.19141 7.80859 0.488281 7 0.488281Z" fill={fill} />
  </svg>
);

export default More;
