import { IFee } from '@constants/types';
import { WhiteFixedFee, WhiteMultipleFee } from '@components/atoms';
import { Fragment } from 'react';

interface IProps {
  fee: IFee | undefined;
}
/* eslint-disable */
const WhiteFeePaige = ({ fee }: IProps) => (
  <Fragment>
    {fee && (fee.percents.length > 1 ? <WhiteMultipleFee fee={fee} /> : <WhiteFixedFee fee={fee} />)}
  </Fragment>
);

export default WhiteFeePaige;
