interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

const CloudUpload = ({
  width = 96,
  fill = '#5D5D5B',
  height = 66,
}: IProps) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M56.0623 36.9375H68.0623L47.9998 17.0625L27.9373 36.9375H39.9373V53.0625H56.0623V36.9375ZM77.4373 25.125C82.5623 25.5 86.9373 27.625 90.5623 31.5C94.1873 35.25 95.9998 39.75 95.9998 45C95.9998 50.5 93.9998 55.25 89.9998 59.25C86.1248 63.125 81.4373 65.0625 75.9373 65.0625H23.9998C17.3748 65.0625 11.6873 62.75 6.93734 58.125C2.31234 53.375 -0.000160217 47.6875 -0.000160217 41.0625C-0.000160217 35.1875 2.06234 29.9375 6.18734 25.3125C10.4373 20.5625 15.4998 17.875 21.3748 17.25C23.9998 12.375 27.6873 8.4375 32.4373 5.4375C37.1873 2.4375 42.3748 0.9375 47.9998 0.9375C54.7498 0.9375 60.9998 3.3125 66.7498 8.0625C72.6248 12.8125 76.1873 18.5 77.4373 25.125Z"
      fill={fill}
    />
  </svg>
);

export default CloudUpload;
